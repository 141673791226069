@import "bootstrap/scss/mixins";

.mat-quoted-text {
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  line-height: 4.5rem;
  color: $cl-white;

  @include media-breakpoint-down(lg) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  strong {
    font-weight: bold;
  }
}

.mat-heading {
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3.65375rem;
  color: $cl-white;
}
