@import "variables";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  z-index: 0;
}

strong {
  font-weight: 500;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.mt-heading {
  padding-top: 2rem;
}

.mt-form-button {
  width: 10rem;
}

/**
 * To truncate text inside of mat-raised-buttons.
 * !important required to override inner material styles.
 */
.mt-truncate-button {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mt-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  line-height: 1em;
  // react to the size of text in placement
  font-size: 1em;

  &-sm {
    font-size: 0.75em;
  }

  &-md {
    font-size: 2em;
  }

  &-lg {
    font-size: 3em;
  }

  &-xl {
    font-size: 4em;
  }

  &-info-box {
    font-size: 1em;
  }

  &--green {
    fill: $cl-green-900;
  }

  &--grey {
    fill: $cl-grey-light;
  }

  &--white {
    fill: $cl-white;
  }

  &--azure {
    fill: $cl-azure-500;
  }
}

.mt-status-icon {
  position: absolute;
  font-size: 92px; // rem units not possible due to mat-spinner input limitations
}

.mt-file-desc-margin {
  margin-top: 3rem;
}

.mt-action-button {
  height: 3.1rem;
  font-size: 1rem;
  font-weight: bold;
  // not happy about the important but what should one do?
  padding: 0 2.5rem !important;

  &--bordered-grey {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $cl-grey-light !important;
  }

  &--bordered-azure {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $cl-azure-500 !important;
  }
}

.mt-add-to-homescreen-button-container {
  position: fixed;
  bottom: 1rem;
  z-index: 1;
}

.mt-card {
  max-width: 26rem;
  height: 100%;

  .mt-action-button {
    width: 100%;
  }

  &--wide {
    max-width: 39rem;
  }
}

.mt-link {
  color: $cl-azure-500;
}

.mt-internal-link {
  color: lighten(black, 50%);
  font-size: $font-smaller;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.mt-cl-grey {
  color: $cl-grey-light;
}

.mt-cl-azure {
  color: $cl-azure-500;
}

a,
a:link {
  text-decoration: none;
}

.mat-typography p {
  margin: 0 0 0.75rem;
}

.mt-text-break {
  word-break: break-all;
}

.mt-sup {
  font-family: $headings-font-family;
  font-weight: 700;
  white-space: nowrap;
}

address {
  font-style: inherit;
}

.mt-nav {
  font-size: $font-small;
  margin: 0 -0.5rem;

  .nav-link {
    white-space: nowrap;
    padding: 0.5rem 0;
    display: inline-block;

    &:not(.mt-link) {
      color: inherit;
    }
  }
}

.mt-main-logo {
  width: 8rem;
  height: 2.22rem;
}

ol > li > ol {
  list-style: lower-alpha;
}

.mt-vat-incl {
  font-size: 0.35em;
  line-height: 1;
  margin-bottom: -1em;
}

.mt-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mt-info-box {
  border-style: solid;
  border-color: $cl-azure-500;
  border-width: 1px;
  border-radius: $default-radius;
}

.mt-close-x {
  margin-top: 0.4rem;
  cursor: pointer;
}

.mt-pricing-link {
  color: black !important;
  cursor: pointer;

  &:hover {
    color: $cl-azure-500;
    transition: 0.2s;
  }
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
  background: unset;
  box-shadow: unset;
  overflow: visible;
}

.mt-card-icon {
  color: black !important;
  font-size: 1rem;
  margin: .5rem 0 0 0 !important;
}

.trigger-menu {
  visibility: hidden;
  position: fixed;
}


.cancel-save-changes-btn {
  height: 3.1rem;
  font-size: 1rem;
  font-weight: bold;
  // not happy about the important but what should one do?
  padding: 0 auto;

  &--bordered-grey {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $cl-grey-light  !important;
  }

  &--bordered-azure {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $cl-azure-500  !important;
  }
}

