// Global material components styles
@use '@angular/material' as mat;
// !DO NOT IMPORT THIS FILE INTO COMPONENTS

@import "material-theme";
@import "variables";
@import "bootstrap/scss/mixins";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-title-typography);`
@include mat.all-legacy-component-typographies($my-title-typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes($my-title-theme);

@include media-breakpoint-down(sm) {
  @include mat.legacy-typography-hierarchy($my-title-typography-sm);
}

.mat-button,
.mat-raised-button,
.mat-flat-button {
  text-transform: uppercase;
  cursor: pointer;
  border-radius: $default-radius;
}

.mat-form-field-appearance-outline:not(.mat-form-field-should-float) .mat-form-field-label {
  margin-top: -0.125em;
}

.mat-card {
  border-radius: $default-radius !important;
  height: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}
