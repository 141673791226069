@import "src/styles/variables";
@import "bootstrap/scss/mixins";

.uploading-item-structure-progress {
  &__text {
    flex: 1 1 auto;
    z-index: 150;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &--detail {
      color: $cl-grey-light;
    }
  }

  &__button,
  &__spinner,
  &__check {
    flex: 0 0 2rem;
    z-index: 150;
    align-self: center;
  }

  &__check {
    color: $cl-green-500;
    animation: check-scale-up ease 750ms;
  }
}

.uploading-item-progress-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  background-color: $cl-azure-100;
  border-radius: $default-radius;
  z-index: 100;
  transition: width 250ms ease-in;

  @for $percent from 1 through 99 {
    &.progress-#{$percent} {
      width: round(percentage($percent*0.01));
    }
  }

  &.progress-100 {
    width: 100%;
    background-color: $cl-green-50;
  }
}

@keyframes check-scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.8);
  }

  100% {
    transform: scale(1);
  }
}
