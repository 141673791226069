@use '@angular/material' as mat;
@import "material-theme";
// functions are needed for variables to work
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$cl-white: #ffffff;
$cl-orange: #EFC340;
$cl-grey-dark: #404040;
$cl-grey-light: #808080;
$cl-grey-lightest: #e1e1e1;

$cl-light-grey: #C4C4C4;
$cl-grey: #314559;
$cl-blue: #02a7e2;

$cl-azure-50: mat.get-color-from-palette($primary-palette, 50);
$cl-azure-100: mat.get-color-from-palette($primary-palette, 100);
$cl-azure-200: mat.get-color-from-palette($primary-palette, 200);
$cl-azure-300: mat.get-color-from-palette($primary-palette, 300);
$cl-azure-400: mat.get-color-from-palette($primary-palette, 400);
$cl-azure-500: mat.get-color-from-palette($primary-palette, 500); // #00a6e2
$cl-azure-600: mat.get-color-from-palette($primary-palette, 600);
$cl-azure-700: mat.get-color-from-palette($primary-palette, 700);
$cl-azure-800: mat.get-color-from-palette($primary-palette, 800);
$cl-azure-900: mat.get-color-from-palette($primary-palette, 900);

$cl-green-50: mat.get-color-from-palette($accent-palette, 50);
$cl-green-100: mat.get-color-from-palette($accent-palette, 100);
$cl-green-200: mat.get-color-from-palette($accent-palette, 200);
$cl-green-300: mat.get-color-from-palette($accent-palette, 300);
$cl-green-400: mat.get-color-from-palette($accent-palette, 400);
$cl-green-500: mat.get-color-from-palette($accent-palette, 500);
$cl-green-600: mat.get-color-from-palette($accent-palette, 600);
$cl-green-700: mat.get-color-from-palette($accent-palette, 700);
$cl-green-800: mat.get-color-from-palette($accent-palette, 800);
$cl-green-900: mat.get-color-from-palette($accent-palette, 900);

$cl-warn: mat.get-color-from-palette($warn-palette, 500);

$default-radius: 0.5rem;

$font-smaller: 0.75rem;
$font-small: 0.9rem;
$font-normal: 1rem;

$size-normal: 1.5rem; // 24px

$foreground-z-index: 150;
$background-z-index: 100;

$font-weight: 600;
